import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';

export const STATUS_BAR_QUERY = graphql`
  query StatusBarQuery {
    datoCmsUpdateBar {
      text
      colour {
        hex
      }
      htmlText
      subscribedColour {
        hex
      }
      label
      active
    }
    datoCmsSubUpdateBar {
      text
      colour {
        hex
      }
      subscribedColour {
        hex
      }
      label
      active
    }
  }
`;

const StatusBar = () => {
  const data = useStaticQuery(STATUS_BAR_QUERY);
  const user = useSelector((state) => state.user.data);
  const isSubscribed = user?.subscribed ?? false;

  const {
    datoCmsUpdateBar: { active, label, htmlText, colour, subscribedColour, text },
    datoCmsSubUpdateBar: {
      active: subActive,
      label: subLabel,
      colour: { hex: subHex },
      text: subText,
    },
  } = data;

  const subscribed_hex = subscribedColour?.hex ?? 'rgb(75, 181, 67)';

  return (
    <>
      {active && (
        <div
          className={user?.email ? 'banner-username' : 'banner'}
          style={{ backgroundColor: isSubscribed ? subscribed_hex : colour?.hex }}
        >
          <div className={user?.email ? 'banner-wrap-username' : 'banner-wrap'}>
            {user?.email ? (
              <div>
                <div>Hi {user.fullname ? user.fullname : user.username}</div>
              </div>
            ) : (
              <>
                {label && (
                  <div className="content-wrap-01">
                    <div>{label.toUpperCase()}</div>
                  </div>
                )}
                <div dangerouslySetInnerHTML={{ __html: htmlText }}></div>
              </>
            )}
          </div>
        </div>
      )}
      {subActive && (
        <div className="banner" style={{ backgroundColor: subHex }}>
          <div className="banner-wrap">
            {subLabel && (
              <div className="content-wrap-01">
                <div>{subLabel.toUpperCase()}</div>
              </div>
            )}
            <div dangerouslySetInnerHTML={{ __html: subText }}></div>
          </div>
        </div>
      )}
    </>
  );
};

export { StatusBar };
