import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import ReactMarkdown from 'react-markdown';

import Logo from '../assets/images/logo-small.svg';

export const FOOTER_QUERY = graphql`
  query FooterQuery {
    allDatoCmsSection(filter: { name: { in: ["Footer Branding", "Footer Contact"] } }) {
      edges {
        node {
          name
          description
        }
      }
    }
    allDatoCmsModule {
      edges {
        node {
          title
          slug
        }
      }
    }
    allDatoCmsNavigation(filter: { name: { in: ["Main", "Payment Methods", "Social Media"] } }) {
      edges {
        node {
          name
          link {
            id
            slug
            externalLink
            label
            icon {
              alt
              url
            }
          }
        }
      }
    }
  }
`;

const Footer = () => {
  const data = useStaticQuery(FOOTER_QUERY);
  const navigations = (navName) =>
    data.allDatoCmsNavigation.edges.filter(({ node }) => node.name === navName);
  const sections = (sectionName) =>
    data.allDatoCmsSection.edges.filter(({ node }) => node.name === sectionName);
  const socialMediaNav = navigations('Social Media')[0].node;
  const mainNav = navigations('Main')[0].node;
  const paymentMethodsNav = navigations('Payment Methods')[0].node;
  const brandingSection = sections('Footer Branding')[0].node;
  const contactSection = sections('Footer Contact')[0].node;

  const renderSocialMedia = () => (
    <div className="social-icon-wrap-circles">
      {socialMediaNav.link.map((socialLink) => (
        <a
          href={socialLink.externalLink}
          className="social-link-circle w-inline-block"
          key={socialLink.externalLink}
        >
          <img src={socialLink.icon.url} alt={socialLink.icon.alt} className="logo-social" />
        </a>
      ))}
    </div>
  );

  const renderPaymentMethodsAndSocialMedia = () => (
    <div className="payment-methods-wrapper">
      {renderSocialMedia()}
      <div className="payment-methods">
        {paymentMethodsNav.link.map((card) => (
          <img src={card.icon.url} alt={card.icon.alt} className="card" key={card.id} width="50" />
        ))}
      </div>
    </div>
  );

  const renderMainNav = () => (
    <div id="w-node-a4feec956a10-ec956a02" className="footer-column">
      <div className="title">MYMATHSCLOUD</div>
      {mainNav.link.map((link) => (
        <Link
          to={link.slug === 'home' ? '/' : `/${link.slug}`}
          className="footer-link"
          key={link.label}
        >
          {link.label}
        </Link>
      ))}
    </div>
  );

  const renderModules = () => (
    <div id="w-node-a4feec956a2f-ec956a02" className="footer-column">
      <div className="title">MODULES</div>
      {data.allDatoCmsModule.edges.map(({ node: link }) => (
        <Link to={`/modules/${link.slug}`} className="footer-link" key={link.slug}>
          {link.title}
        </Link>
      ))}
    </div>
  );

  const renderContact = () => (
    <div id="w-node-a3e637d5ce8a-ec956a02" className="footer-column">
      <div className="title">CONTACT</div>
      <ReactMarkdown children={contactSection.description} className="footer-p" />
    </div>
  );

  return (
    <div className="footer">
      <div className="container">
        <div className="w-layout-grid footer-grid">
          <div id="w-node-a4feec956a05-ec956a02" className="footer-column">
            <img src={Logo} width="200" alt="Logo" className="footer-logo" />
            <ReactMarkdown children={brandingSection.description} />
          </div>
          {renderMainNav()}
          {renderModules()}
          {renderContact()}
        </div>
        {renderPaymentMethodsAndSocialMedia()}
        <div className="footer-legal-dark">
          <div className="legal">
            © 2020{' '}
            <a href="https://mymathscloud.com" className="footer-brand-link">
              MyMathsCloud
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Footer };
