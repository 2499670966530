/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import { useSelector } from 'react-redux';

const generateKey = (pre) => {
  return `${pre}_${new Date().getTime()}`;
};

const SEO = ({
  canonicalUrl,
  description,
  image,
  lang,
  seoKeywords,
  schema,
  shouldIndex,
  title,
  noIndex,
}) => {
  const location = useLocation();
  const {
    datoCmsSite: { faviconMetaTags, globalSeo, locale },
    site: {
      siteMetadata: { siteUrl },
    },
    datoCmsSubscriptionPage
  } = useStaticQuery(graphql`
    query LayoutQuery {
      datoCmsSite {
        faviconMetaTags {
          ...GatsbyDatoCmsFaviconMetaTags
        }
        locale
        globalSeo {
          fallbackSeo {
            description
            image {
              url
            }
            title
            twitterCard
          }
          twitterAccount
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      datoCmsSubscriptionPage {
        showAdsToSubscribedUsers
      }
    }
  `);

  const user = useSelector((state) => state.user.data);
  const isSubscribed = user?.subscribed ?? false;
  const { showAdsToSubscribedUsers } = datoCmsSubscriptionPage;

  const keywords =
    seoKeywords &&
    seoKeywords.length > 0 &&
    seoKeywords.map((keyword) => keyword && keyword.keyword).join(', ');

  const seo = {
    title: title || globalSeo.fallbackSeo.title,
    description: description || globalSeo.fallbackSeo.description,
    image: (image && image.url) || globalSeo.fallbackSeo.image.url,
    url: `${siteUrl}${location.pathname}`,
    canonicalUrl: canonicalUrl || location.pathname,
    keywords,
  };

  React.useEffect(() => {
    setTimeout(() => {
        if (user?.subscribed && !showAdsToSubscribedUsers) return;
    
        const existing = document.head.querySelector('#google-ad-script');
        if (existing) return;
    
        const googleAdScript = document.createElement('script');
        googleAdScript.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3527692984183862';
        googleAdScript.async = true;
        googleAdScript.crossOrigin = 'anonymous';
        googleAdScript.id = 'google-ad-script'
    
        document.head.appendChild(googleAdScript);

      /*
        const existing = document.head.querySelector('#fuse-ad-script'); 
        if (existing) return;
    
        const fuseAdScript = document.createElement('script');
        fuseAdScript.src = 'https://cdn.fuseplatform.net/publift/tags/2/3749/fuse.js';
        fuseAdScript.async = true;
        fuseAdScript.id = 'fuse-ad-script';
    
        document.head.appendChild(fuseAdScript);

        // GAM 71161633/MYMACL_mymathscloud/header
        const navBar = document.querySelector('.top-bar');
        const fuseHeader = document.createElement('div');
        fuseHeader.setAttribute('data-fuse', '23198949897');
        navBar.append(fuseHeader);


        const fuseStylePrivacyToolStyle = document.createElement('style');
        fuseStylePrivacyToolStyle.innerHTML = `
          #fuse-privacy-tool {
            font-size: 0.5rem;
          }
        `;
        document.head.appendChild(fuseStylePrivacyToolStyle);

        const fuseStylePrivacyToolDiv = document.createElement('div');
        fuseStylePrivacyToolDiv.setAttribute('data-fuse-privacy-tool', '');
        document.body.appendChild(fuseStylePrivacyToolDiv);

        const sidebarLeft = document.createElement('div');
        sidebarLeft.setAttribute('style', 'width: calc((100% - 1200px) / 2); position: fixed; height: 100%; left: 0; top: 0; display: flex; align-items: center; z-index: 9999;');
        document.body.prepend(sidebarLeft);

        const fuseHeaderStickySidebarLhs = document.createElement('div');
        fuseHeaderStickySidebarLhs.setAttribute('data-fuse', '23198950164');
        fuseHeaderStickySidebarLhs.setAttribute('style', 'height: 600px;');
        sidebarLeft.append(fuseHeaderStickySidebarLhs);

        // GAM 71161633/MYMACL_mymathscloud/headersticky_sidebar_rhs
        const sidebarRight = document.createElement('div');
        sidebarRight.setAttribute('style', 'width: calc((100% - 1200px) / 2); position: fixed; height: 100%; right: 0; top: 0; display: flex; align-items: center; z-index: 9999;');
        document.body.prepend(sidebarRight);

        const fuseHeaderStickySidebarRhs = document.createElement('div');
        fuseHeaderStickySidebarRhs.setAttribute('data-fuse', '23198950167');
        fuseHeaderStickySidebarRhs.setAttribute('style', 'height: 600px;');
        sidebarRight.append(fuseHeaderStickySidebarRhs);
        */
        
    }, 2000);
  }, [])

  return (
    <Helmet title={seo.title} htmlAttributes={{ lang: lang || locale }}>
      <meta name="title" content={seo.title} key="meta-title" />
      {seo.description && <meta name="description" content={seo.description} key="description" />}

      {seo.keywords && <meta name="keywords" content={seo.keywords} />}

      {faviconMetaTags &&
        faviconMetaTags.tags &&
        faviconMetaTags.tags.length > 0 &&
        faviconMetaTags.tags.map((tag) => (
          <link {...tag.attributes} key={generateKey(tag.attributes.href)} />
        ))}

      <meta name="image" content={seo.image} />

      {seo.url && <meta property="og:url" content={seo.url} />}
      <meta property="og:type" content="website" />
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      {/* {seo.image && <meta property="og:locale" content="en_GB" /> }
      {seo.image && <meta property="og:site_name" content="My Maths Cloud" /> } */}
      {seo.image && <meta property="og:image" content={seo.image} />}
      {/* {seo.image && <meta property="og:image:secure_url" content={seo.image} />}
      {seo.image && <meta property="og:image:width" content="256" />}
      {seo.image && <meta property="og:image:height" content="256" />}
      {seo.image && <meta property="og:image:alt" content={seo.title} />}
      {seo.image && <meta property="og:og:image:type" content="image/jpg" />}
     {seo.image && <meta property="og:image:alt" content="My Maths Cloud" /> } */}

      <meta property="twitter:card" content={globalSeo.fallbackSeo.twitterCard} />
      {globalSeo.twitterAccount && (
        <meta name="twitter:creator" content={globalSeo.twitterAccount} />
      )}
      {seo.title && <meta property="twitter:title" content={seo.title} />}
      {seo.description && <meta property="twitter:description" content={seo.description} />}
      {seo.image && <meta property="twitter:image" content={seo.image} />}
      {seo.image && <meta name="twitter:image" content={seo.image} />}

      {!noIndex && shouldIndex && <link rel="canonical" href={seo.canonicalUrl} key="canonical" />}
      {!noIndex && shouldIndex && <meta name="robots" content="all" key="robots" />}

      {noIndex && <meta name="robots" content="noindex" />}

      {schema && <script type="application/ld+json">{JSON.stringify(schema)}</script>}
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: `en`,
  description: ``,
  shouldIndex: true,
};

SEO.propTypes = {
  canonicalUrl: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  shouldIndex: PropTypes.bool,
  title: PropTypes.string,
};

export { SEO };
